<script setup>
const userStore = useUserStore();
const marketplaceStore = useMarketplaceStore();

defineProps({
  nft: {
    type: Object,
    default: () => {},
  },
  loadingImage: {
    type: String,
    default: () => "lazy",
  },
  preloadImage: {
    type: Boolean,
    default: () => false,
  },
});

defineEmits(["nftLikeAction"]);
</script>

<template>
  <div class="h-full max-h-[490px]">
    <div
      class="flex h-full flex-col justify-between overflow-hidden rounded-3xl bg-white shadow-around shadow-black/25 dark:bg-[#0F2775] dark:shadow-[#00bbba]"
      draggable="false"
      @click="navigateTo(`/details/${nft?._id}`)"
    >
      <div
        v-if="nft?.listingType === 'auction'"
        class="absolute -top-3 right-0 z-[5] flex w-fit items-center rounded-3xl bg-white text-xs font-semibold dark:bg-[#0F2260]"
      >
        <MoleculeCountdownTimer
          :end="new Date(nft?.startDate) > new Date() ? nft?.startDate : nft?.endDate"
          :class="[
            { 'gap-1': !nft.isFinishedAuction },
            'custom-gradient relative flex justify-center rounded-3xl bg-secondary-gradient bg-clip-text px-3 py-1.5 text-transparent before:bg-secondary-gradient',
          ]"
          >{{
            !nft.isFinishedAuction
              ? new Date(nft?.startDate) > new Date()
                ? "Sale starts in"
                : "Sale ends in"
              : "Sale ended"
          }}</MoleculeCountdownTimer
        >
      </div>
      <div>
        <AtomImageNuxtImage
          class="aspect-square size-full max-h-64 rounded-t-3xl object-cover transition-all"
          :src="nft?.assetPreview || nft?.asset"
          :loading="loadingImage"
          :preload="preloadImage"
          :alt="`nft ${nft.name}`"
          resize="450x253"
          draggable="false"
        />
        <div class="-mt-8 flex flex-col items-center gap-0.5 px-5 text-center dark:text-white">
          <NuxtLink
            :aria-label="nft?.name"
            :to="`/user-collection/${nft.owner?._id}`"
            :class="[
              nft.owner?.userName === userStore.user?.userName ? 'pointer-events-none' : 'pointer-events-auto',
              'custom-gradient  relative rounded-full before:p-1',
            ]"
          >
            <div
              v-if="!nft.owner?.avatar"
              class="m-px flex size-16 items-center justify-center rounded-full bg-white dark:bg-[#0F2775]"
            >
              <IconUserGradient class="size-7" />
            </div>
            <AtomImageNuxtImage
              v-else
              class="block aspect-square size-16 rounded-full object-cover transition-all"
              :src="nft.owner?.avatar"
              bucket-alias="avatars"
              resize="64x64"
              :alt="`avatar ${nft.owner?.fullName || nft.owner.userName}`"
            />
            <IconCrown2 v-if="nft.owner.verify" class="absolute -right-3.5 -top-3 size-8 rotate-90" />
          </NuxtLink>
          <NuxtLink :to="`/details/${nft?._id}`" class="line-clamp-1 text-xl font-semibold xl:text-2xl">
            {{ nft?.name }}
          </NuxtLink>
          <div class="line-clamp-1 text-base text-gray-500 dark:text-white/60 2xl:text-lg">
            by
            <NuxtLink
              :aria-label="nft.owner?.fullName || nft.owner.userName"
              :to="`/user-collection/${nft.holder?._id}`"
              :class="[
                nft.owner?.userName === userStore.user?.userName ? 'pointer-events-none' : 'pointer-events-auto',
                'text-blue-primary hover:text-blue-primary/50 dark:text-white dark:hover:text-white/70',
              ]"
              >{{ nft.owner?.BSCWalletAddress?.slice(0, 4) }}...{{ nft.owner?.BSCWalletAddress?.slice(-6) }}</NuxtLink
            >
          </div>
          <p class="line-clamp-2 min-h-[48px] text-base 2xl:text-lg">
            {{ nft?.description }}
          </p>
        </div>
      </div>
      <div :class="[nft?.type === 'BNFT' ? 'grid-cols-1' : 'grid-cols-5', 'mt-3 grid w-full px-5 pb-5']">
        <div
          v-if="nft?.type !== 'BNFT'"
          class="col-span-2 flex items-center justify-between text-start text-base font-semibold lg:text-xl"
        >
          <ClientOnly v-if="nft?.price">
            <template #default>
              <span v-if="nft?.price" class="bg-gradient-blue bg-clip-text text-transparent dark:bg-primary-gradient"
                >{{ numberToShortNumber(nft.price) }} CEEK</span
              >
            </template>
            <template #fallback>
              <div class="h-4 w-16 rounded-2xl bg-gray-300 dark:dark:bg-[#2D059E]"></div>
            </template>
          </ClientOnly>
        </div>
        <div
          v-if="nft?.type !== 'BNFT'"
          class="col-span-2 flex items-center justify-between text-start text-lg font-semibold lg:text-xl"
        >
          <ClientOnly v-if="nft?.price">
            <template #default>
              <span class="text-[#6658d4] dark:text-white/60"
                >${{ numberToShortNumber(nft.price * marketplaceStore.GET_EXCHANGE_RATE.tokenPrice) }}</span
              >
            </template>
            <template #fallback>
              <div class="h-4 w-16 rounded-2xl bg-gray-300 dark:dark:bg-[#2D059E]"></div>
            </template>
          </ClientOnly>
        </div>
        <div class="flex items-center gap-2 place-self-end">
          <ClientOnly>
            <template #default>
              <MoleculeButtonLike :nft="nft" @nft-like-action="(val) => $emit('nftLikeAction', val)" />
            </template>
            <template #fallback>
              <div class="size-7 rounded-full bg-gray-300 dark:dark:bg-[#2D059E]"></div>
            </template>
          </ClientOnly>

          <MoleculeShare :url="`/details/${nft?._id}`" :title="nft?.name" :image="nft?.assetPreview || nft?.asset" />
        </div>
      </div>
    </div>
  </div>
</template>
